import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { makeNullable, makeRequired, link } from 'lib/validators';
import Button from 'components/common/Button';
import ModalPage from 'components/common/ModalPage';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormInput from 'components/form/FormInput';
import FormValidationError from 'components/form/FormValidationError';

const validationSchema = yup.object({
  text: makeRequired(yup.string()),
  href: makeNullable(link),
});

type FormValues = yup.InferType<typeof validationSchema>;

type FormTipTapEditorLinkModalProp = {
  title?: string;
  initialValues?: FormValues;
  onHide: () => void;
  onSubmit: (values: FormValues) => void;
};

const FormTipTapEditorLinkModal = ({
  title = 'Add link',
  initialValues = {},
  onHide,
  onSubmit,
}: FormTipTapEditorLinkModalProp) => {
  const { handleSubmit, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      text: initialValues.text ?? '',
      href: initialValues.href ?? '',
    },
  });

  return (
    <ModalPage onHide={onHide} show className="max-w-lg">
      <div>
        <Navbar
          className="sticky z-40 top-0 inset-x-0 h-14 shrink-0 bg-white border-b border-gray-400"
          left={
            <NavbarIconButton
              as="button"
              type="button"
              onClick={onHide}
              icon={faTimes}
              className="-ml-4"
            />
          }
          center={<NavbarTitle title={title} />}
        />

        <div className="p-6 md:p-8">
          <FormFieldGroup>
            <Controller
              name="text"
              control={control}
              render={({ field, fieldState }) => (
                <FormNode>
                  <FormLabel htmlFor={field.name} isRequired>
                    Link text
                  </FormLabel>
                  <FormInput
                    type="text"
                    status={fieldState.error ? 'error' : 'default'}
                    autoFocus={!initialValues.text}
                    {...field}
                  />
                  {fieldState.error && <FormValidationError message={fieldState.error.message} />}
                </FormNode>
              )}
            />

            <Controller
              name="href"
              control={control}
              render={({ field, fieldState }) => (
                <FormNode>
                  <FormLabel htmlFor={field.name} isRequired>
                    URL
                  </FormLabel>
                  <FormInput
                    type="text"
                    autoFocus={!!initialValues.text}
                    status={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                  {fieldState.error && <FormValidationError message={fieldState.error.message} />}
                </FormNode>
              )}
            />
          </FormFieldGroup>
        </div>

        <div className="border-t border-gray-400 p-6 md:px-8 flex justify-end items-center gap-2">
          <Button
            as="button"
            type="button"
            onClick={onHide}
            color="gray-800"
            className="font-medium w-full"
            outline
          >
            Cancel
          </Button>
          <Button
            as="button"
            type="submit"
            color="primary"
            className="font-medium w-full"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </div>
    </ModalPage>
  );
};

export default FormTipTapEditorLinkModal;
