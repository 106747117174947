import { useEffect, useState } from 'react';
import { faPencil, faTrash, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import {
  faBold,
  faCaretDown,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faQuoteRight,
  faUnderline,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import {
  useEditor,
  BubbleMenu,
  EditorContent,
  getMarkRange,
  Editor,
  JSONContent,
} from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { motion } from 'framer-motion';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

import cx from '@/lib/cx';
import Card from '@/components/common/Card';
import ContextMenu from '@/components/common/ContextMenu';
import ContextMenuLink from '@/components/common/ContextMenuLink';
import HorizontalScrollContainer from '@/components/common/HorizontalScrollContainer';
import PopMenu from '@/components/common/PopMenu';

import style from '../FormInput.module.css';
import editorStyle from '../FormTextEditor/Editor.module.css';
import EditorToolbarButton from '../FormTextEditor/EditorToolbarButton';
import { ButtonExtension } from './ButtonExtension';
import LinkModal from './LinkModal';

type EditorFeature =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'lists'
  | 'link'
  | 'quote'
  | 'divider'
  | 'titles'
  | 'button';

const PRESETS: Record<string, EditorFeature[]> = {
  none: [],
  inline: ['bold', 'italic', 'underline'],
  basic: ['bold', 'italic', 'underline', 'lists', 'link', 'quote', 'divider'],
  advanced: [
    'titles',
    'bold',
    'italic',
    'underline',
    'lists',
    'link',
    'quote',
    'button',
    'divider',
  ],
};

const LINK_OPTIONS = {
  openOnClick: false,
  autolink: true,
  defaultProtocol: 'https',
  protocols: ['mailto'],
};

type FormTextEditorProps = {
  allow?: EditorFeature[];
  preset?: keyof typeof PRESETS;
  value?: JSONContent;
  onChange: (value?: JSONContent) => void;
  status?: 'default' | 'error' | 'warning' | 'success' | 'info';
  disabled?: boolean;
  readOnly?: boolean;
  wrapperClassName?: string;
} & Omit<React.ComponentProps<typeof EditorContent>, 'value' | 'onChange' | 'editor'>;

type LinkModalAction = {
  type: 'link';
  initialValues?: { text?: string; href?: string };
};

type ButtonModalAction = {
  type: 'button';
  initialValues?: { text?: string; href?: string };
};

type ModalAction = LinkModalAction | ButtonModalAction;

function getLinkProps(editor: Editor, node: string = 'link') {
  const { state } = editor.view;

  let text = '';
  if (!state.selection.empty) {
    text = state.doc.textBetween(state.selection.from, state.selection.to);
  }
  if (editor.isActive(node)) {
    const range = getMarkRange(state.selection.$from, state.schema.marks.link);
    if (range) text = state.doc.textBetween(range.from, range.to);
  }

  return {
    text: text ? text : editor.getAttributes(node).text,
    href: editor.getAttributes(node).href,
  };
}

function stripEmptyContentNodes(node?: JSONContent | null): JSONContent | null {
  if (!node) return null;

  if (node.type === 'text') return node.text ? node : null;

  if (isEmpty(node.content)) return node;

  node.content = node.content
    .map((contentNode) => stripEmptyContentNodes(contentNode))
    .filter((contentNode) => contentNode !== null);

  return node;
}

const FormTextEditor = ({
  allow = [],
  preset = 'basic',
  value,
  onChange,
  placeholder = '',
  status = 'default',
  disabled = false,
  readOnly = false,
  className = '',
  wrapperClassName = '',
  ...props
}: FormTextEditorProps) => {
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);

  const features = [...allow, ...PRESETS[preset]];
  const editor = useEditor({
    // Clone the value in case the original is frozen such as when it's
    // passed directly from the server
    content: stripEmptyContentNodes(cloneDeep(value)),
    editable: !readOnly && !disabled,
    editorProps: {
      attributes: {
        class: cx('p-4 overflow-y-auto h-56 rich-text', editorStyle.content, className),
      },
    },
    extensions: [
      StarterKit.configure({
        blockquote: features.includes('quote') ? {} : false,
        bold: features.includes('bold') ? {} : false,
        bulletList: features.includes('lists') ? {} : false,
        codeBlock: false,
        heading: features.includes('titles') ? { levels: [2, 3] } : false,
        horizontalRule: features.includes('divider') ? {} : false,
        italic: features.includes('italic') ? {} : false,
        listItem: features.includes('lists') ? {} : false,
        orderedList: features.includes('lists') ? {} : false,
      }),
      Placeholder.configure({ placeholder }),
      ...(features.includes('button') ? [ButtonExtension.configure(LINK_OPTIONS)] : []),
      ...(features.includes('underline') ? [Underline] : []),
      ...(features.includes('link')
        ? [
            Link.extend({
              addKeyboardShortcuts() {
                return {
                  'Mod-k': () => {
                    setModalAction({
                      type: 'link',
                      initialValues: getLinkProps(this.editor as Editor),
                    });
                    return true;
                  },
                };
              },
              inclusive: true,
            }).configure(LINK_OPTIONS),
          ]
        : []),
    ],
    immediatelyRender: false,
  });

  let state: string = status;
  if (readOnly) state = 'readonly';
  if (disabled) state = 'disabled';

  let titleLabel = 'Normal';
  if (editor?.isActive('heading', { level: 2 })) titleLabel = 'Title';
  if (editor?.isActive('heading', { level: 3 })) titleLabel = 'Subtitle';

  // Sync value -> content
  useEffect(() => {
    const cleanedValue = stripEmptyContentNodes(cloneDeep(value));
    if (!editor || isEqual(cleanedValue, editor.getJSON())) return;
    editor.commands.setContent(cleanedValue);
  }, [value, editor]);

  // Sync content -> value
  useEffect(() => {
    if (!editor) return;

    const updateValue = () => onChange(editor.getJSON());
    editor.on('update', updateValue);
    return () => {
      editor.off('update', updateValue);
    };
  }, [editor, onChange]);

  if (!editor) return null;
  return (
    <>
      {modalAction?.type === 'link' && (
        <LinkModal
          title={editor.isActive('link') ? 'Edit Link' : 'Add Link'}
          initialValues={modalAction.initialValues}
          onHide={() => setModalAction(null)}
          onSubmit={({ href, text }) => {
            if (!href) {
              editor.chain().focus().extendMarkRange('link').unsetLink().run();
              setModalAction(null);
              return;
            }

            const { state } = editor.view;
            const textRange =
              getMarkRange(state.selection.$from, state.schema.marks.link) || state.selection;

            editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href })
              .insertContentAt(textRange, text)
              .run();

            setModalAction(null);
          }}
        />
      )}

      {modalAction?.type === 'button' && (
        <LinkModal
          title={editor.isActive('button') ? 'Edit Button' : 'Add Button'}
          initialValues={(() => {
            if (editor.isActive('button')) {
              const { selection } = editor.state;
              const pos = selection.$from.before();
              const buttonNode = editor.state.doc.nodeAt(pos);
              return {
                href: buttonNode?.attrs.href || '',
                text: buttonNode?.textContent || '', // This gets the full text content
              };
            }
            return modalAction.initialValues;
          })()}
          onHide={() => setModalAction(null)}
          onSubmit={({ href, text }) => {
            if (!href || !text) {
              editor.chain().focus().clearNodes().run();
              setModalAction(null);
              return;
            }

            if (editor.isActive('button')) {
              const { tr, selection } = editor.state;
              const pos = selection.$from.before(); // Position of the button node

              tr.setNodeMarkup(pos, null, { href, text });

              const buttonNode = tr.doc.nodeAt(pos);
              if (buttonNode) {
                const buttonContentPos = pos + 1; // Position inside the button node
                tr.insertText(text, buttonContentPos, buttonContentPos + buttonNode.content.size);
              }

              editor.view.dispatch(tr);

              editor.chain().focus().run();
            } else {
              // Insert new button
              editor
                .chain()
                .focus()
                .deleteSelection()
                .insertContent(`<div class="button" href="${href}">${text}</div>`)
                .run();
            }

            setModalAction(null);
          }}
        />
      )}

      <div className={cx(style.base, style[state], wrapperClassName, 'overflow-hidden')}>
        {features.length > 0 && (
          <div className="sticky top-0 inset-x-0 z-10 bg-white border-b border-gray-400">
            <HorizontalScrollContainer className={cx('py-2 px-3 flex items-center')}>
              <div className="flex items-center gap-x-3">
                {features.includes('titles') && (
                  <PopMenu
                    positions={['bottom']}
                    align="start"
                    menuClassName="w-40"
                    trigger={
                      <div className="px-3 py-1 w-32 border border-gray-400 hover:border-gray-500 rounded-md flex items-center justify-between">
                        <p className="text-sm">{titleLabel}</p>
                        <FontAwesomeIcon icon={faCaretDown} size="sm" />
                      </div>
                    }
                    closeOnClick
                  >
                    <ContextMenu>
                      <ContextMenuLink
                        as="button"
                        type="button"
                        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        label="Title"
                        theme="bordered"
                      />
                      <ContextMenuLink
                        as="button"
                        type="button"
                        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                        label="Subtitle"
                        theme="bordered"
                      />
                      <ContextMenuLink
                        as="button"
                        type="button"
                        onClick={() => editor.commands.setParagraph()}
                        label="Normal"
                        theme="bordered"
                      />
                    </ContextMenu>
                  </PopMenu>
                )}

                <div className="flex items-center gap-x-1 empty:hidden">
                  {features.includes('bold') && (
                    <EditorToolbarButton
                      icon={faBold}
                      isActive={editor.isActive('bold')}
                      onClick={() => editor.chain().focus().toggleBold().run()}
                    />
                  )}
                  {features.includes('italic') && (
                    <EditorToolbarButton
                      icon={faItalic}
                      isActive={editor.isActive('italic')}
                      onClick={() => editor.chain().focus().toggleItalic().run()}
                    />
                  )}

                  {features.includes('underline') && (
                    <EditorToolbarButton
                      icon={faUnderline}
                      isActive={editor.isActive('underline')}
                      onClick={() => editor.chain().focus().toggleUnderline().run()}
                    />
                  )}
                </div>

                {features.includes('lists') && (
                  <div className="flex flex-row items-center gap-x-1">
                    <EditorToolbarButton
                      icon={faListUl}
                      isActive={editor.isActive('bulletList')}
                      onClick={() => editor.chain().focus().toggleBulletList().run()}
                    />
                    <EditorToolbarButton
                      icon={faListOl}
                      isActive={editor.isActive('orderedList')}
                      onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    />
                  </div>
                )}

                <div className="flex items-center gap-x-1 empty:hidden">
                  {features.includes('quote') && (
                    <EditorToolbarButton
                      icon={faQuoteRight}
                      isActive={editor.isActive('blockquote')}
                      onClick={() => editor.chain().focus().toggleBlockquote().run()}
                    />
                  )}
                  {features.includes('link') && (
                    <EditorToolbarButton
                      icon={faLink}
                      isActive={editor.isActive('link')}
                      onClick={() =>
                        setModalAction({ type: 'link', initialValues: getLinkProps(editor) })
                      }
                    />
                  )}
                </div>

                {(['button', 'divider'] as EditorFeature[]).some((feature) =>
                  features.includes(feature)
                ) && (
                  <PopMenu
                    positions={['bottom']}
                    align="end"
                    menuClassName="w-40"
                    trigger={
                      <div className="group font-medium text-gray-700 flex items-center gap-x-2 px-2 py-1 hover:text-gray-800 hover:bg-gray-300 transition-colors duration-200 rounded-md">
                        <p className="text-sm">Insert</p>
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          size="sm"
                          className="text-gray-500 group-hover:text-gray-600 transition-colors duration-200"
                        />
                      </div>
                    }
                    closeOnClick
                  >
                    <ContextMenu>
                      {features.includes('button') && (
                        <ContextMenuLink
                          as="button"
                          type="button"
                          onClick={() =>
                            setModalAction({
                              type: 'button',
                              initialValues: getLinkProps(editor, 'button'),
                            })
                          }
                          label="Button"
                          theme="bordered"
                        />
                      )}
                      {features.includes('divider') && (
                        <ContextMenuLink
                          as="button"
                          type="button"
                          onClick={() => editor.chain().focus().setHorizontalRule().run()}
                          label="Horizontal line"
                          theme="bordered"
                        />
                      )}
                    </ContextMenu>
                  </PopMenu>
                )}
              </div>
            </HorizontalScrollContainer>
          </div>
        )}
        {editor && (
          <BubbleMenu
            editor={editor}
            shouldShow={(props) => props.editor.isActive('button') || props.editor.isActive('link')}
            tippyOptions={{ duration: 100 }}
          >
            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="block">
              <Card
                as="span"
                padding="none"
                radius="sm"
                depth="sm"
                className="p-2 flex items-center gap-x-1"
                border
              >
                <EditorToolbarButton
                  icon={faPencil}
                  onClick={() => {
                    setModalAction({
                      type: editor.isActive('link') ? 'link' : 'button',
                      initialValues: getLinkProps(
                        editor,
                        editor.isActive('link') ? 'link' : 'button'
                      ),
                    });
                  }}
                />
                <EditorToolbarButton
                  icon={faTrash}
                  onClick={() =>
                    editor.isActive('link')
                      ? editor.chain().focus().unsetLink().run()
                      : editor.chain().focus().unsetButton().run()
                  }
                />
                <EditorToolbarButton
                  icon={faExternalLink}
                  onClick={() =>
                    window.open(
                      editor.isActive('link')
                        ? editor.getAttributes('link').href
                        : editor.getAttributes('button').href
                    )
                  }
                />
              </Card>
            </motion.span>
          </BubbleMenu>
        )}
        <EditorContent editor={editor} {...props} />
      </div>
    </>
  );
};

export default FormTextEditor;
